// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
// import * as ActiveStorage from "@rails/activestorage"

Turbolinks.start()

// Styles
import "styles/inspinia"
import "styles/application"

// Javascript
import "pace-js"
import "jquery-slimscroll"
import "metismenu"

import 'bootstrap'

import "inspinia/inspinia.js"

import "controllers"
